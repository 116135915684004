<template>
  <div>
    <h1 class="mb-4">
      Frequently Asked Questions
    </h1>
    <p class="mb-6">
      Search our frequently asked questions and answers.
    </p>

    <v-row>
      <v-col>
        <v-text-field
          v-model="filter"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search"
          class="mb-4"
          type="text"
          hide-details="auto"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-col>
      <v-col class="d-none d-md-block text-right">
        <v-btn class="ms-3" rounded @click="openAll">
          Open All
        </v-btn>
        <v-btn class="ms-3" rounded @click="closeAll">
          Close All
        </v-btn>
      </v-col>
    </v-row>

    <v-expansion-panels v-model="open" multiple>
      <v-expansion-panel
        v-for="(item, i) in items"
        :key="i"
      >
        <v-expansion-panel-header>
          {{ item.question }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ item.answer }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiMagnify,
      },
    }
  },
  data() {
    return {
      open: this.$store.getters.supportOpen,
      filter: this.$store.getters.supportFilter,
    }
  },
  computed: {
    items() {
      var items
      if (this.filter) {
        items = this.$store.getters.supportItems.filter((item) => {
          return (item.question + item.answer).indexOf(this.filter) !== -1
        })
      } else {
        items = this.$store.getters.supportItems
      }
      return items
    },
  },
  watch: {
    open: function(val) {
      this.$store.commit('supportOpen', val)
    },
    filter: function(val) {
      this.$store.commit('supportFilter', val)
    },
  },
  methods: {
    openAll() {
      this.open = this.items.map((item, i) => i)
    },
    closeAll() {
      this.open = []
    },
  },
}
</script>
